import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './Header';
import Footer from './Footer';
import { Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap";
import api from "./Api";
import Axios from 'axios';
import GooglePlacesAutocomplete , { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import SimpleCrypto from "simple-crypto-js";
const _secretKey =
    '"vWoSv4QY6yfSNf0lzRrcISO6XlCu/xpzCY+N5C83GEzCs7eOlyUdu6SNv39r4a+5+IL7fjP1uAJwD5IUbCdH6sHlxObh6VC9o8fYbQmGcfk4EnOdV>';
const Cryp = new SimpleCrypto(_secretKey);
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_APP_API_KEY = process.env.REACT_APP_API_KEY;
const LoggedIn = sessionStorage.getItem('details') ? sessionStorage.getItem('details') : null


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData:'',
      loginModal:false,
      showOTP:false,
      loading:false,
      phone_number:"",
      otp:"",
      otp1:"",
      otp2:"",
      otp3:"",
      otp4:"",
      step2:false,
      step1:true,
      address:"",
      latitude:"",
      longitude:"",
      name:"",
      email:"",
      landmark:"",
      referer_code:"",
      ontheGoModal:false
      // Other state variables if needed
    };
  }

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('Position:', position);
          this.setState(
            {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
            () => this.getAddress() // Get address after state is set
          );
        },
        (error) => {
          console.error('Error:', error);
          this.setState({ error: error.message });
        }
      );
    } else {
      this.setState({ error: 'Geolocation is not supported by this browser.' });
    }
  }

  getAddress = async () => {
    const { latitude, longitude } = this.state;
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === 'OK') {
        const formattedAddress = data.results[0].formatted_address;
        this.setState({ city: formattedAddress });
      } else {
        console.error('Geocode API error:', data.status);
      }
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };



  isLogin = () => {
      if(LoggedIn){
        this.setState({
            ontheGoModal:true
          })
      }else{
        this.setState({
            loginModal:true
          })
      }

  }

  toggledetails = () => {
    this.setState({
        loginModal:false,
        ontheGoModal:false
      })
  }

  handleInput(e) {
    this.setState({ [e.target.name]: e.target.value })
}

onSelectHome = (value) => {
    console.log("address", value.label);

    this.setState({
        city: value.label,
    })

    geocodeByPlaceId(value.value.place_id)
      .then(results => {
        const addressComponents = results[0].address_components;
        const city_name = addressComponents.find(component =>
          component.types.includes('locality')
        )?.long_name;

        if (city_name) {
          console.log('City:', city_name);
          this.setState({ city_name:city_name });
        } else {
          console.log('City not found');
        }
      })
      .catch(error => console.error('Error:', error));

    setTimeout(() => {
        this.getLatLngHome(this.state.city)
    }, 100);
}

getLatLngHome = () => {
    geocodeByAddress(this.state.city)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => this.setState({
            latitude: lat,
            longitude: lng
        }))
        .catch(error => console.error('Error', error));
    setTimeout(() => {
        console.log(this.state.latitude)
    }, 500);
}

register = (e) => {
    e.preventDefault();
    if (this.state.name == "") {
        toast.error("Please enter Name");
        return false;
    }
    if (this.state.email == "") {
        toast.error("Please enter Email");
        return false;
    }
    if (this.state.city == "") {
        toast.error("Please enter Flat No.");
        return false;
    }
    if (this.state.address == "") {
        toast.error("Please enter Address");
        return false;
    }

        this.setState({
            loading: true,
        });
        const formData = new FormData();
        formData.append('name', this.state.name);
        formData.append('email', this.state.email);
        formData.append('city', this.state.city);
        formData.append('address', this.state.address);
        formData.append('referer_code', this.state.referer_code);
        formData.append('latitude', this.state.latitude);
        formData.append('longitude', this.state.longitude);
        formData.append('city_name', this.state.city_name);
        formData.append('phone', this.state.phone_number);
        Axios.post(`${BASE_URL}/customer/customer?API_KEY=${process.env.REACT_APP_API_KEY}`,formData, {
        }).then(response => {
          console.log(response)
                if (response.data?.id) {
                    this.setState({
                        step2: false,
                        step1:true,
                        loading: false,
                        loginModal:false
                    });
                    const details = Cryp.encrypt(response.data);
                    sessionStorage.setItem("details", details);
                    toast.success("Register Successfully");

                } else {
                    this.setState({
                        showOTP: false,
                        loading: false,
                        otp:"",
                        step2:true
                    });
                }
            })
            .catch((error) =>
                this.setState({
                    error: error.message || "An error occurred",
                    loading: false,
                })
            );

}

verifyOTP = (e) => {
    var otp = this.state.otp1+this.state.otp2+this.state.otp3+this.state.otp4;
    console.log(otp);
    console.log(this.state.otp)
    if(otp == this.state.otp){
        this.setState({
            loading: true,
            step1:false
        });
        const formData = new FormData();
        formData.append('id', this.state.phone_number);
        Axios.post(`${BASE_URL}/customer/loginNew?API_KEY=${process.env.REACT_APP_API_KEY}`,formData, {
        }).then(response => {
          console.log(response)
                if (response.data?.id) {
                    this.setState({
                        showOTP: false,
                        loading: false,
                        otp:"",
                        loginModal:false
                    });
                    const details = Cryp.encrypt(response.data);
                    sessionStorage.setItem("details", details);
                    toast.success("Login Successfully");

                } else {
                    this.setState({
                        showOTP: false,
                        loading: false,
                        otp:"",
                        step2:true
                    });
                }
            })
            .catch((error) =>
                this.setState({
                    error: error.message || "An error occurred",
                    loading: false,
                })
            );
    }else{
        toast.error("Invalid OTP");
    }
}

getOTP = (e) => {
    e.preventDefault();
    if (this.state.phone_number == "") {
        toast.error("Please enter mobile number");
        return false;
    }
    this.setState({
        loading: true,
    });
    Axios.get(`${BASE_URL}/customer/sendSMS?phone_number=${this.state.phone_number}&API_KEY=${process.env.REACT_APP_API_KEY}`)
  .then(response => {
      console.log(response)
    // response.data?.id
            if (response.data?.status == true) {
                this.setState({
                    showOTP: true,
                    loading: false,
                    otp:response.data?.otp
                });
                const details = Cryp.encrypt(response.data);
                sessionStorage.setItem("details", details);

                // setTimeout(() => {
                //     window.location.reload();
                // }, 300);
            } else {
                // NotificationManager.error("error", "Invalid Credentials.");
                return false;
            }
        })
        .catch((error) =>
            this.setState({
                error: error.message || "An error occurred",
                loading: false,
            })
        );
};


  render() {

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };

      const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        const { carouselState: { currentSlide } } = rest;
        return (
          <div className="carousel-button-group" style={{ position: 'absolute' }}>

            <div class="swiper-button-prev" className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()} ></div>
            <div class="swiper-button-next" onClick={() => next()} ></div>


          </div>
        );
      };

    return (
      <>
      <ToastContainer />
      {this.state.loading && (
        <div class="loading-overlay">
            <span
                class="fa fa-spinner fa-3x fa-spin"
                style={{ float: "center" }}
            ></span>
        </div>
    )}
        <div className="body-head">
          <div>
            <Header />
            <section class="home-services py-80 bg-light">
                <div class="container">
                    <div class="row text-center mb-4">

                        <div class="col-xl-6 col-lg-12 col-md-12">

                            <div class="header-title-wrapper text-left">
                                <h1 class="fw-bold">Your Home <span class="text-primary">Services</span> Experts</h1>
                                <p class="text-muted">
                                    At Meehelp, we're dedicated to making your home the haven you deserve.
                                    Say goodbye to stress and hassle, and hello to comfort and convenience.
                                </p>
                            </div>

                            <div class="top-use-services">
                                <div class="row">

                                    <div class="col-md-6">
                                        <div class="service-card-v text-center shadow-sm p-4">
                                            <img src="assets/images/on-the-go-icon.png" class="mx-auto mb-3" width="50"
                                                alt="Clock"/>
                                            <div class="service-content">
                                                <h4>On The Go</h4>
                                                <p class="text-muted">On Demand helper for pre-set work. Max 2 hours work.</p>
                                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.meehelp.meehelp&hl=en" class="btn primary with-icon md on-the-go-btn" id="onTheGoButton">Book Now <i
                                                        class="fas fa-chevron-right"></i></a>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-md-6">
                                        <div class="service-card-v text-center shadow-sm p-4">
                                            <img src="assets/images/prebook-icon.png" class="mx-auto mb-3" width="50"
                                                alt="Calendar"/>
                                            <div class="service-content">
                                                <h4>Prebook Service</h4>
                                                <p class="text-muted">Prebook helper for 2 to 12 hours work (Single day
                                                    service).</p>
                                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.meehelp.meehelp&hl=en" class="btn primary with-icon md">Book Now <i
                                                        class="fas fa-chevron-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">

                                    <div class="col-md-12 mt-lg-4 mt-md-4 mt-sm-4">
                                        <div class="service-card-h text-center shadow-sm p-4">
                                            <img src="assets/images/regular-icon.png" class="mx-auto" width="50" alt="Maid"/>
                                            <div class="service-content">
                                                <div class="service-plan-content">
                                                    <h4>Regular Monthly Cooks/Maids</h4>
                                                    <p class="text-muted">Hire maids/cooks on a monthly basis without brokerage.
                                                    </p>
                                                </div>
                                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.meehelp.meehelp&hl=en" class="btn primary with-icon md">Book Now <i
                                                        class="fas fa-chevron-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-6 col-lg-12 col-md-12 banner-content-img">

                            <div class="row d-flex">
                                <div class="col-md-5">
                                    <div class=" img-wrapper-banner image-anime">
                                        <img src="assets/images/image.png" class="img-fluid" alt="Service 1"/>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="img-wrapper-banner image-anime">
                                        <img src="assets/images/image-1.png" class="img-fluid" alt="Service 1"/>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="img-wrapper-banner image-anime">
                                        <img src="assets/images/image-2.png" class="img-fluid" alt="Service 1"/>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="img-wrapper-banner image-anime">
                                        <img src="assets/images/image-3.png" class="img-fluid" alt="Service 1"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row g-4">

                    </div>
                </div>
            </section>


            <section class="service-slider py-80">
                <div class="container">

                    <div class="section-title t_left text-center">
                        <h5>Instant Service</h5>
                        <h3 data-cursor="-opaque">Book Now Our Top Used Service</h3>
                    </div>


                    <div class="swiper serviceSwiper">
                    <Carousel arrows={false} customButtonGroup={<ButtonGroup />}
                            swipeable={false}
                            draggable={false}
                            showDots={false}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={5000}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                            >
                            <div class="swiper-slide">
                                <div class="service-card">
                                    <img src="assets/images/services-icons/Cooking.png" alt="Cooking"/>
                                    <h5>Cooking</h5>
                                    <button class="service-btn">→</button>
                                </div>
                                </div>
                                <div>
                                <div class="service-card">
                                    <img src="assets/images/services-icons/Cleaning.png" alt="Cleaning"/>
                                    <h5>Cleaning</h5>
                                    <button class="service-btn">→</button>
                                </div>
                                </div>
                                <div>
                                <div class="service-card">
                                    <img src="assets/images/services-icons/Baby-care.png" alt="Baby Care/Nanny"/>
                                    <h5>Baby Care/Nanny</h5>
                                    <button class="service-btn">→</button>
                                </div>
                                </div>
                                <div>
                                <div class="service-card">
                                    <img src="assets/images/services-icons/Patient-Care.png" alt="Elder Care"/>
                                    <h5>Elder Care/Patient Care</h5>
                                    <button class="service-btn">→</button>
                                </div>
                            </div>


                            </Carousel>

                            <Carousel arrows={false} customButtonGroup={<ButtonGroup />}
                            swipeable={false}
                            draggable={false}
                            showDots={false}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={5000}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                            >
                            <div class="swiper-slide">
                            <div class="service-card">
                                <img src="assets/images/services-icons/Kitchen-help.png" alt="Kitchen Help"/>
                                <h5>Kitchen Help</h5>
                                <button class="service-btn">→</button>
                            </div>
                                </div>
                                <div>
                                <div class="service-card">
                                    <img src="assets/images/services-icons/Washroom.png" alt="Washroom Cleaning"/>
                                    <h5>Washroom Cleaning</h5>
                                    <button class="service-btn">→</button>
                                </div>
                                </div>
                                <div>
                                <div class="service-card">
                                    <img src="assets/images/services-icons/Car-Wash.png" alt="Car Wash"/>
                                    <h5>Car Wash</h5>
                                    <button class="service-btn">→</button>
                                </div>
                                </div>
                                <div>
                                <div class="service-card">
                                    <img src="assets/images/services-icons/Laundry.png" alt="Laundry & Drying"/>
                                    <h5>Laundry & Drying</h5>
                                    <button class="service-btn">→</button>
                                </div>


                            </div>


                            </Carousel>



                    </div>

                </div>
            </section>

            <section class="features-section py-80">
                <div class="container">

                    <div class="section-title t_left text-center">
                        <h5>FEATURES</h5>
                        <h3>Experience Excellence in Every Detail</h3>
                    </div>
                    <div class="row">

                        <div class="col-lg-4 col-md-6">
                            <div class="feature-card">
                                <img src="assets/images/featurs/image.png" alt="Expertise"/>
                                <div class="feature-content">
                                    <div class="features-content-data">
                                        <h4>Expertise</h4>
                                        <p>Our helper are highly trained and equipped to handle any task with expertise and
                                            efficiency.</p>
                                    </div>
                                    <a class="link with-icon" href="#" class="view-details">View details <i
                                            class="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="feature-card">
                                <img src="assets/images/featurs/image-1.png" alt="Reliability"/>
                                <div class="feature-content">
                                    <div class="features-content-data">
                                        <h4>Reliability</h4>
                                        <p>Count on us to be there when you need us most, providing prompt and reliable service
                                            that you can trust.</p>
                                    </div>
                                    <a class="link with-icon" href="#" class="view-details">View details <i
                                            class="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="feature-card">
                                <img src="assets/images/featurs/image-2.png" alt="Peace of Mind"/>
                                <div class="feature-content">
                                    <div class="features-content-data">
                                        <h4>Peace of Mind</h4>
                                        <p>Rest easy knowing that your home is in good hands. We prioritize safety and quality
                                            in everything we do.</p>
                                    </div>
                                    <a class="link with-icon" href="#" class="view-details">View details <i
                                            class="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="stats-section pb-80">
                <div class="container">
                    <div class="row">

                        <div class="col-lg-3 col-md-6">
                            <div class="stat-card">
                                <img src="assets/images/stats/download-icon.png" alt="Download"/>
                                <h3 class="counter" data-target="12500">100K+</h3>
                                <p>Downloads</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6">
                            <div class="stat-card">
                                <img src="assets/images/stats/reviews-icon.png" alt="Reviews"/>
                                <h3 class="counter" data-target="3200">10K+</h3>
                                <p>Reviews</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6">
                            <div class="stat-card">
                                <img src="assets/images/stats/service-icon.png" alt="Booked Service"/>
                                <h3 class="counter" data-target="10000">50K+</h3>
                                <p>Booked Services</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6">
                            <div class="stat-card">
                                <img src="assets/images/stats/cities-icon.png" alt="Cities Covered"/>
                                <h3 class="counter" data-target="10">50+</h3>
                                <p>Cities Covered</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="testimonial-section">
                <div class="container">

                    <div class="section-title t_center">
                        <h5>Customer Testimonials</h5>
                        <h3>Our User Feedbacks</h3>
                    </div>

                    <Carousel arrows={false} customButtonGroup={<ButtonGroup />}
                            swipeable={false}
                            draggable={false}
                            showDots={false}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={5000}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                            >
                            <div class="swiper testimonialSwiper"><div class="swiper-slide">
                                <div class="testimonial-card">
                                    <div class="stars">★★★★★</div>
                                    <h3>"Sanjana!"</h3>
                                    <p>Very Helpful. Helps save commission amount and hire directly. </p>
                                </div>
                            </div></div>
                            <div class="swiper testimonialSwiper"><div class="swiper-slide">
                                <div class="testimonial-card">
                                    <div class="stars">★★★★★</div>
                                    <h3>"Ramya!"</h3>
                                    <p>Tried their on the go service. The helper actually came in 50 mins. </p>
                                </div>
                            </div></div>
                            <div class="swiper testimonialSwiper"><div class="swiper-slide">
                                <div class="testimonial-card">
                                    <div class="stars">★★★★★</div>
                                    <h3>"Roma!"</h3>
                                    <p>I took leap of faith by buying the subscription. It worked for me. I got near by help </p>
                                </div>
                            </div></div>
                            <div class="swiper testimonialSwiper"><div class="swiper-slide">
                                <div class="testimonial-card">
                                    <div class="stars">★★★★★</div>
                                    <h3>"Bharti!"</h3>
                                    <p>Sitting in patna i was able to find a helper for my parents in delhi via this app. </p>
                                </div>
                            </div></div>
                            </Carousel>


                    </div>
            </section>


            <section class="about-section py-80" id="aboutMeehelp">
                <div class="container">

                    <div class="section-title t_center">
                        <h5>About</h5>
                        <h3>About MeeHelp</h3>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="feature-card">
                                <div class="feature-content">
                                    <img src="assets/images/Any-where.png"/>
                                    <div class="features-content-data">
                                        <h4>On-the-go app available anywhere, anytime</h4>
                                        <p>Need a quick fix? Download the app and get an instant service after choosing the appropriate help as per your requirement, MeeHelp is available at your service at all times!</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="feature-card">
                                <div class="feature-content">
                                    <img src="assets/images/Love.png"/>
                                    <div class="features-content-data">
                                        <h4>Customer service at lightning speed</h4>
                                        <p>We understand the need for urgency and that's why we have superfast customer service team available to resolve your issues.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="feature-card">
                                <div class="feature-content">
                                    <img src="assets/images/free-replacement.png"/>
                                    <div class="features-content-data">
                                        <h4>Free Replacement anytime</h4>
                                        <p>Your satisfaction matters to us and that's why we offer replacements at no extra cost.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="download-our-app py-80">
                <div class="container">
                    <div class="row app-section">

                        <div class="col-md-6">
                            <div class="app-card">
                                <div class="download-content">
                                    <h2>Get The MeeHelp App</h2>
                                    <p>Order From Our Mobile App And Get 20% Off</p>
                                    <p>We will send you a link, open it on your phone to download the app</p>

                                    {/* <div class="d-flex gap-3">
                                        <div class="input-field">
                                            <input type="text" class="default md" placeholder="Enter your email"/>
                                        </div>
                                        <button class="btn secondary md">Subscribe</button>
                                    </div> */}

                                    <div class="app-buttons">
                                        <a target="_blank" href="https://apps.apple.com/in/app/meehelp-find-helpers-near-you/id1625634794" class="shake"><img src="assets/images/app-store-download.png"
                                                alt="App Store"/></a>
                                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.meehelp.meehelp&hl=en" class="shake"> <img src="assets/images/google-play-download.png"
                                                alt="Google Play"/></a>
                                    </div>

                                    <div class="phone-image shake">
                                        <img src="assets/images/user-app.png" alt="MeeHelp User App"/>
                                    </div>
                                </div>

                                <img class="bg-element bounce" src="assets/images/bg-rounder-element.png"/>
                            </div>
                        </div>


                        <div class="col-md-6">
                            <div class="app-card">
                                <div class="download-content">
                                    <h2>Get The MeeHelp Partner App</h2>
                                    <p>Get Work From Our Partner App</p>
                                    <p>We will send you a link, open it on your phone to download the partner app</p>

                                    {/* <div class="d-flex gap-3">
                                        <div class="input-field">
                                            <input type="text" class="default md" placeholder="Enter your email"/>
                                        </div>
                                        <button class="btn secondary md">Subscribe</button>
                                    </div> */}

                                    <div class="app-buttons">
                                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.aletia.mee_help_business&hl=en" class="shake"><img src="assets/images/app-store-download.png"
                                                alt="App Store"/></a>
                                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.aletia.mee_help_business&hl=en" class="shake"> <img src="assets/images/google-play-download.png"
                                                alt="Google Play"/></a>
                                    </div>

                                    <div class="phone-image shake">
                                        <img src="assets/images/helper-app.png" alt="MeeHelp User App"/>
                                    </div>
                                </div>

                                <img class="bg-element bounce" src="assets/images/bg-rounder-element.png"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section class="subscription-area pb-80">
                <div class="container">
                    <div class="subscription-section">
                        <div class="content">

                            <div class="col-lg-4">
                                <h2>Ready to Transform Your Home?</h2>
                            </div>

                            <div class="col-lg-5">
                                <div class="d-flex gap-3">
                                    <div class="input-field">
                                        <input type="text" class="default md" placeholder="Enter your email"/>
                                    </div>
                                    <button class="btn primary md">Subscribe</button>
                                </div>
                                <p class="privacy-text">By subscribing you agree to our Privacy Policy</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <Footer />
          </div>
        </div>

        <Modal
            modalClassName="modal user-list"
            isOpen={this.state.loginModal}
            toggle={this.toggledetails}
            size="xl"
            // style={{"width": "51%"}}
        >
            <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title">
                    <a href="#" class="logo">
                        <img src="assets/images/logo-1.png" alt="Me Help"/>
                    </a>
                </h5>
                <button onClick={this.toggledetails} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-6 col-lg-5 left-content d-flex align-items-center">
                            <div class="swiper onboarding-swiper">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <div class="onboard-card">
                                            <div class="onboard-img">
                                                <img src="assets/images/model-img/mehelp-main.png"/>
                                            </div>
                                            <p>MeeHelp's team went above and beyond to fix my plumbing issue
                                                promptly.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="onboard-card">
                                            <div class="onboard-img">
                                                <img src="assets/images/model-img/on-the-go.png"/>
                                            </div>
                                            <p>On The Go
                                            </p>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="onboard-card">
                                            <div class="onboard-img">
                                                <img src="assets/images/model-img/Part-time.png"/>
                                            </div>
                                            <p>Part Time
                                            </p>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="onboard-card">
                                            <div class="onboard-img">
                                                <img src="assets/images/model-img/full-time.png"/>
                                            </div>
                                            <p>Full Time
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-pagination"></div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-7 right-content">

                            {this.state.step1 && <> <div class="auth-box">
                                <div class="section-title t_left text-center">
                                    <h3>Login Your Account</h3>
                                </div>
                                {/* <form> */}
                                    <div class=" my-4">
                                        <label class="mb-2">Enter Mobile Number</label>
                                        <div class="input-group mb-3">
                                            <span class="input-group-text">+91</span>
                                            <input type="text" name="phone_number" value={this.state.phone_number} onChange={e => { this.handleInput(e) }} class="form-control" placeholder="000 000 0000"/>
                                        </div>

                                        {this.state.showOTP && <> <div class="mt-5">
                                            <label class="text-left mb-2">We have sent a code to {this.state.phone_number}</label>
                                            <div class="password-inputs-otp">
                                                <input type="text"   name="otp1" onChange={e => { this.handleInput(e) }} maxlength="1" class="form-control" id="password1"/>
                                                <input type="text" value={this.state.otp2} name="otp2" onChange={e => { this.handleInput(e) }} maxlength="1" class="form-control" id="password2"/>
                                                <input type="text" value={this.state.otp3} name="otp3" onChange={e => { this.handleInput(e) }} maxlength="1" class="form-control" id="password3"/>
                                                <input type="text" value={this.state.otp4} name="otp4" onChange={e => { this.handleInput(e) }} maxlength="1" class="form-control" id="password4"/>
                                            </div>

                                        </div>
                                        </>}
                                    </div>
                                    {!this.state.showOTP && <> <button class="btn primary md w-100" onClick={this.getOTP}>GET OTP</button> </>}

                                    {this.state.showOTP && <> <button class="btn primary md w-100" onClick={this.verifyOTP}>Verify OTP</button> </>}
                                {/* </form> */}
                            </div>
                            </>}


                            {this.state.step2 && <> <div class="auth-box">
                                    <div class="section-title t_left text-center">
                                        <h3>Create Account</h3>
                                        <label>Please fill in the form below to create an account isn't it easy?</label>
                                    </div>
                                    {/* <form> */}
                                        <div class="mb-3">
                                            <label class="form-label">Full Name</label>
                                            <input type="text" value={this.state.name} name="name" onChange={e => { this.handleInput(e) }} class="form-control" placeholder="Enter Your Full Name" />
                                        </div>

                                        <div class="mb-3">
                                            <label class="form-label">Email</label>
                                            <input type="email" value={this.state.email}  onChange={e => { this.handleInput(e) }} name="email" class="form-control" placeholder="Enter Your Email Address" />
                                        </div>

                                        <div class="mb-3">
                                            <label class="form-label">Flat No.</label>
                                            <input type="text" name="address" value={this.state.address}  onChange={e => { this.handleInput(e) }}  class="form-control" placeholder="Enter Your Flat No/House No." />
                                        </div>

                                        <div class="mb-3">
                                            <label class="form-label">Address</label>
                                            <div class="input-group">
                                                {/* <input type="text" class="form-control" placeholder="Enter Your Address" /> */}
                                                <GooglePlacesAutocomplete
                                                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                                    selectProps={{
                                                        value: this.state.city ? { label: this.state.city, value: this.state.city } : null,
                                                    onChange: this.onSelectHome, // Correct prop name is `onChange`
                                                    placeholder: 'Address...',
                                                    }}
                                                />
                                                <span class="input-group-text">
                                                    <img src="https://img.icons8.com/ios-filled/20/marker.png" alt="Location" />
                                                </span>
                                            </div>
                                        </div>

                                        <div class="my-3">
                                            <label class="form-label">Referral Code</label>
                                            <input type="text" name="referer_code" value={this.state.referer_code}  onChange={e => { this.handleInput(e) }} class="form-control" placeholder="Enter Code" />
                                        </div>

                                        <button type="submit" onClick={this.register} class="btn primary md w-100 mt-2">Submit</button>
                                    {/* </form> */}
                                </div>
                                </>}
                        </div>
                    </div>
                </div>

            </div>
</div>



        </Modal>



        <Modal
            modalClassName="modal user-list"
            isOpen={this.state.ontheGoModal}
            toggle={this.toggledetails}
            size="lg"
            // style={{"width": "51%"}}
        >
            <div class=" service-selection-modal" id="onTheGoModal" >
        <div class="">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title mb-0">On The go</h3>
                    <button onClick={this.toggledetails} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 right-content">
                                <div class="auth-box">
                                    {/* <form> */}
                                        <div class="selection-section">
                                            <h4>Select Service</h4>
                                            <div class="selection-options service-options">
                                                <div class="option-card selected" data-value="cooking">
                                                    <img src="assets/images/services-icons/Cooking.png" alt="Cooking" />
                                                    <span>Cooking</span>
                                                </div>
                                                <div class="option-card" data-value="cleaning">
                                                    <img src="assets/images/services-icons/Cleaning.png" alt="Cleaning" />
                                                    <span>Cleaning</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="selection-section">
                                            <h4>Food Type</h4>
                                            <div class="selection-options food-options">
                                                <div class="option-card selected" data-value="veg">
                                                    <img src="assets/images/model-img/on-the-go/image.png" alt="Veg" />
                                                    <span>Veg</span>
                                                </div>
                                                <div class="option-card" data-value="non-veg">
                                                    <img src="assets/images/model-img/on-the-go/image 10.png" alt="Non-veg" />
                                                    <span>Non-veg</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="selection-section">
                                            <h4>Gender</h4>
                                            <div class="selection-options gender-options">
                                                <div class="option-card selected" data-value="male">
                                                    <img src="assets/images/model-img/on-the-go/male.png" alt="Male" / >
                                                    <span>Male</span>
                                                </div>
                                                <div class="option-card" data-value="female">
                                                    <img src="assets/images/model-img/on-the-go/femail.png" alt="Female"/>
                                                    <span>Female</span>
                                                </div>
                                                <div class="option-card" data-value="no-preference">
                                                    <img src="assets/images/model-img/on-the-go/other.png" alt="No Preference" />
                                                    <span>No Preference</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="selection-section">
                                            <h4>Members</h4>
                                            <div class="selection-options members-option">
                                                <div class="option-card selected" data-value="3">
                                                    <img src="assets/images/model-img/on-the-go/group.png" alt="3 Members" />
                                                    <span>3 Member Select</span>
                                                </div>
                                            </div>

                                            <div class="range-slider-container">
                                                <div class="range-slider">
                                                    <div class="slider-track"></div>
                                                    <div class="slider-thumb">
                                                        <span>3</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="selection-section">
                                            <h4>Set your search range.</h4>

                                            <div class="search-range-options">
                                                <div class="range-option" data-range="0-2">
                                                    <h5>0-2 km</h5>
                                                    <p>Rare chances to acceptance</p>
                                                </div>
                                                <div class="range-option" data-range="2-5">
                                                    <h5>2-5 km</h5>
                                                    <p>Decent chance of acceptance</p>
                                                    <span class="discount-badge">5% discount</span>
                                                </div>
                                                <div class="range-option" data-range="5-10">
                                                    <h5>5-10 km</h5>
                                                    <p>High chance of acceptance</p>
                                                    <span class="discount-badge">10% discount</span>
                                                </div>
                                                <div class="range-option" data-range="10-15">
                                                    <h5>10-15 km</h5>
                                                    <p>Very high chances of acceptance</p>
                                                    <span class="discount-badge">15% discount</span>
                                                </div>
                                            </div>

                                            <div class="helper-status" style={{"display":"none"}}>
                                                <p>Helper Not Available On This Distance</p>
                                            </div>

                                            <div class="price-display">
                                                <div class="current-price">₹280</div>
                                                <div class="original-price">₹456</div>
                                                <div class="discount-percentage">50% off</div>
                                            </div>
                                        </div>

                                        <button type="button" class="btn primary md w-100" id="connectNowBtn">Connect Now</button>
                                    {/* </form> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>





        </Modal>
      </>
    );
  }
}

export default Home;