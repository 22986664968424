import React, { Component } from 'react';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData:'',
      // Other state variables if needed
    };
  }


  render() {

    return (
      <>
        <div className="body-head">
          <div>
          <footer class="footer">
        <div class="container">
            <div class="row">

                <div class="col-md-3">
                    <h4 class="footer-title mb-15">Contact</h4>
                    <div class="contact-info">
                        <p>Andheri West , mumbai</p>
                        <p><a href="https://wa.me/7738085995" target="_blank">WhatsApp Chat support :-7738085995</a></p>
                        {/* <p>Email: help@meehelp.com</p> */}
                    </div>

                    <div class="social-icons">
                        <a href="https://www.instagram.com/meehelpofficial/"><i class="fab fa-instagram"></i></a>
                        <a href="https://www.linkedin.com/company/meehelp-india/"><i class="fab fa-linkedin"></i></a>
                        <a href="https://www.facebook.com/meehelp/"><i class="fab fa-facebook"></i></a>
                        <a href="https://x.com/MeeHelpIndia"><i class="fab fa-twitter"></i></a>
                    </div>
                </div>


                <div class="col-md-3">
                    <h4 class="footer-title">Help</h4>
                    <ul>
                        <li><a href="#aboutMeehelp">About Company</a></li>

                    </ul>
                </div>


                <div class="col-md-3">
                    <h4 class="footer-title">Meehelp in media</h4>
                    <ul>
                        {/* <li><a href="#">Home</a></li> */}
                        <li><a href="https://yourstory.com/2025/03/meehelp-revolutionising-home-services-via-ai-powered-solutions">Youtstore</a></li>
                        <li><a href="https://entrackr.com/2023/04/mumbai-based-meehelp-offers-an-uber-like-service-for-domestic-help/">Entrack</a></li>
                        {/* <li><a href="#">FAQ</a></li> */}
                    </ul>
                </div>


                {/* <div class="col-md-3">
                    <h4 class="footer-title">Company</h4>
                    <ul>
                        <li><a href="#">Bookings & Cancellations</a></li>
                        <li><a href="#">Website Terms</a></li>
                    </ul>
                </div> */}
            </div>


            <div class="footer-bottom">
                <p>2025 © Meehelp. All Rights Reserved.</p>
                <div class="policy-links">
                    <a href="#">Privacy & Policy</a> | <a href="#">Terms & Condition</a>
                </div>
            </div>
        </div>
    </footer>

          </div>
        </div>
      </>
    );
  }
}

export default Footer;