import React, { Component } from 'react';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: '',
      dropdownOpen: false,
      latitude:"",
      longitude: '',
      city:""
    };

  }

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('Position:', position);
          this.setState(
            {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
            () => this.getAddress() // Get address after state is set
          );
        },
        (error) => {
          console.error('Error:', error);
          this.setState({ error: error.message });
        }
      );
    } else {
      this.setState({ error: 'Geolocation is not supported by this browser.' });
    }
  }

  getAddress = async () => {
    const { latitude, longitude } = this.state;
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === 'OK') {
        const formattedAddress = data.results[0].formatted_address;
        this.setState({ city: formattedAddress });
      } else {
        console.error('Geocode API error:', data.status);
      }
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };


  toggleDropdown = () => {

    this.setState({
        dropdownOpen:this.state.dropdownOpen ? false : true
    })
    console.log(this.state.dropdownOpen)
  };

  render() {
    return (
      <>
        <div className="body-head">
          <div>
            <nav className="navbar">
              <div className="container">
                {/* Logo */}
                <a href="#" className="logo">
                  <img src="assets/images/logo-1.png" alt="Me Help" />
                </a>

                {/* Location Input */}
                <div className="location-dropdown">
                  <div className="input-group">
                    <input
                      type="text"
                      value={this.state.city}
                      className="form-control address-input"
                      placeholder="Enter Your Address"
                    />
                    <span className="input-group-text">
                      <img
                        src="https://img.icons8.com/ios-filled/20/marker.png"
                        alt="Location"
                      />
                    </span>
                  </div>
                </div>

                {/* Profile Menu */}
                <div className="profile-menu" id="profileMenu">
                  <img
                    className="profile-icon"
                    src="assets/images/user-icon.png"
                    alt="User"
                    id="profileIcon"
                    onClick={this.toggleDropdown}
                  />
                  {this.state.dropdownOpen && <>
                    <div className="dropdown" id="dropdownMenu">
                      <a href="#" onClick={() => console.log('Open Login Modal')}>
                        Login
                      </a>
                      <a href="#" onClick={() => console.log('Open Signup Modal')}>
                        Create New Account
                      </a>
                      <a href="#">Profile</a>
                      <a href="#">Settings</a>
                      <a href="#">Logout</a>
                    </div>
                  </>}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </>
    );
  }
}

export default Header;
