import './App.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from './web/Home';
import Privacy from './web/Privacy';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" caseSensitive={false} element={<Home />} />
        <Route path="/privacy-policy" caseSensitive={false} element={<Privacy />} />
      </Routes>
    </Router>
  );
}

export default App;


